<template>
  <div id="new-password">
    <v-toolbar flat color="dark">
      <v-btn @click="checkBackFunction()" icon>
        <v-icon color="primary" size="30"> mdi-arrow-left </v-icon>
      </v-btn>
      <v-toolbar-title class="steps" v-if="gs.isMobile()">
        {{ $t("alpha_register_step_count", { step: step, totalSteps: 3 }) }}
      </v-toolbar-title>
      <img
        v-if="!gs.isMobile()"
        src="../../../../../assets/dealboard-logo-white.svg"
        height="40px"
        class="pl-10"
      />
      <v-spacer />
      <v-menu
        v-if="
          !gs.isMobile() &&
          !$route.name.includes('AlphaRegisterClient') &&
          $route.name != 'AlphaUserRegisterClient' &&
          $route.name != 'WelcomePage'
        "
        style="z-index: 1000"
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-card
            class="btn_lang d-flex mr-5"
            v-if="selectedLanguage != null"
            style="width: 125px"
            height="30px"
            min-width="100px"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              max-width="23px"
              height="23px"
              :src="selectedLanguageAvatar()"
            ></v-img>
            <p class="p_br" v-if="selectedLanguage.value == 'pt'">
              PORTUGUÊS/BR
            </p>
            <p class="p_en" v-else>ENGLISH</p>
          </v-card>
          <v-btn
            v-else
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account-voice</v-icon>
          </v-btn>
        </template>

        <v-list :tile="false" flat nav color="dark">
          <v-list-item
            v-for="(l, i) in langs"
            :key="i"
            @click="changeLanguage(l)"
          >
            <v-list-item-title v-text="l.text" style="line-height: 1.4" />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-progress-linear
      v-if="gs.isMobile()"
      color="primary"
      height="4"
      class="dxa-progress-bar"
      style="margin-bottom: -10px"
      :value="(step * 100) / 3"
    ></v-progress-linear>
    <v-container
      :class="gs.isMobile() ? 'pa-4' : ''"
      :style="gs.isMobile() ? '' : 'width: fit-content'"
    >
      <v-slide-y-transition appear>
        <div class="justify-center">
          <h2
            class="confirm_title mt-2"
            :style="gs.isMobile() ? '' : 'text-align: left'"
          >
            {{ $t("pwd_reset") }}
          </h2>
          <h3
            class="confirm_content mt-6"
            :style="gs.isMobile() ? '' : 'text-align: left; color: #9C9C9C'"
          >
            {{ $t("enter_new_pwd") }}
          </h3>
          <div class="mt-12">
            <div v-if="!gs.isMobile()">
              <h3 class="steps mb-3" style="color: var(--dark)">
                {{
                  $t("alpha_register_step_count", { step: step, totalSteps: 3 })
                }}
              </h3>
              <v-progress-linear
                color="primary"
                height="4"
                class="dxa-progress-bar"
                style="margin-bottom: -10px"
                :value="(step * 100) / 3"
              ></v-progress-linear>
              <v-card class="desktop_card">
                <!-- ao matar essa foto, quebramos o layout! por isso retiramos ela da tela com um opacity: 0 -->
                <img
                  src="../../../../../assets/dxa-x.png"
                  class="x_logo"
                  style="opacity: 0"
                />
                <img
                  src="../../../../../assets/dxa-stars-background.png"
                  class="stars"
                />
                <div class="card_content">
                  <h3 class="confirm_content" style="text-align: left">
                    {{ $t("enter_confirm_new_pwd") }}
                  </h3>
                  <v-form
                    @submit.stop.prevent="checkForm()"
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    style="width: 50%"
                  >
                    <div class="div_password mt-11">
                      <h3 class="password_title">{{ $t("new_password") }}</h3>
                      <v-text-field
                        @keydown.space.prevent
                        solo
                        flat
                        color="#DADADA"
                        background-color="#E9E9E9"
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          required,
                          min_valid,
                          uppercase_required,
                          lowercase_required,
                          number_required,
                          accent_valid,
                          special_valid,
                          passwordMinCharacter,
                        ]"
                        :type="show1 ? 'text' : 'password'"
                        name="password"
                        ref="password"
                        counter
                        @click:append="show1 = !show1"
                        class="my-input-style mt-2"
                      />
                    </div>

                    <div class="div_password">
                      <h3 class="password_title">
                        {{ $t("confirm_new_password") }}
                      </h3>
                      <v-text-field
                        solo
                        flat
                        color="#DADADA"
                        background-color="#E9E9E9"
                        v-model="rePassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[required, passwordsMatch]"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-2"
                        @click:append="show2 = !show2"
                        class="my-input-style mt-2"
                      />
                    </div>

                    <div>
                      <ValidateStrengthPassword :userPassword="password" />
                    </div>

                    <div class="my-4">
                      <ul style="display: flex; flex-direction: column-reverse">
                        <li
                          v-for="i in 5"
                          :key="i"
                          style="
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 15px;
                            color: var(--dark);
                          "
                        >
                          {{ $t(`password_rule_${i}`) }}
                        </li>
                        <!-- min character key -->
                        <li
                          style="
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 15px;
                            color: var(--dark);
                          "
                        >
                          {{ $t(`password_min_character`) }}
                        </li>
                      </ul>
                    </div>

                    <pages-btn
                      type="submit"
                      block
                      :loading="loading"
                      color="primary"
                      class="send_btn v-btn--text"
                      style="margin-top: 20%; color: var(--white)"
                      >{{ $t("reset") }}</pages-btn
                    >
                  </v-form>
                  <!-- componente de erro -->
                  <div>
                    <v-alert class="ma-2" v-if="error" type="error">
                      {{ $t(error) }}
                    </v-alert>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else>
              <v-form
                @submit.stop.prevent="checkForm()"
                ref="form"
                v-model="valid"
                :lazy-validation="lazy"
              >
                <div class="div_password">
                  <h3 class="password_title">{{ $t("new_password") }}</h3>
                  <v-text-field
                    @keydown.space.prevent
                    solo
                    flat
                    color="#DADADA"
                    background-color="#E9E9E9"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      required,
                      min_valid,
                      uppercase_required,
                      lowercase_required,
                      number_required,
                      accent_valid,
                      special_valid,
                      passwordMinCharacter,
                    ]"
                    :type="show1 ? 'text' : 'password'"
                    name="password"
                    ref="password"
                    counter
                    @click:append="show1 = !show1"
                    class="my-input-style mt-2"
                  />
                </div>

                <div class="div_password">
                  <h3 class="password_title">
                    {{ $t("confirm_new_password") }}
                  </h3>
                  <v-text-field
                    solo
                    flat
                    color="#DADADA"
                    background-color="#E9E9E9"
                    v-model="rePassword"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[required, passwordsMatch]"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-2"
                    @click:append="show2 = !show2"
                    class="my-input-style mt-2"
                  />
                </div>

                <div>
                  <ValidateStrengthPassword :userPassword="password" />
                </div>

                <div class="my-4">
                  <ul style="display: flex; flex-direction: column-reverse">
                    <li
                      v-for="i in 5"
                      :key="i"
                      style="
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 15px;
                        color: var(--dark);
                      "
                    >
                      {{ $t(`password_rule_${i}`) }}
                    </li>
                    <!-- min character key -->
                    <li
                      style="
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 15px;
                        color: var(--dark);
                      "
                    >
                      {{ $t(`password_min_character`) }}
                    </li>
                  </ul>
                  <!-- componente de erro -->
                  <div style="margin-top: 32px">
                    <v-alert class="ma-2 mb-5" v-if="error" type="error">
                      {{ $t(error) }}
                    </v-alert>
                  </div>
                </div>

                <div style="margin-top: 30vh; margin-bottom: 20px">
                  <pages-btn
                    type="submit"
                    block
                    :loading="loading"
                    color="primary"
                    class="send_btn v-btn--text"
                    style="
                      margin-left: auto;

                      color: var(--white);
                    "
                    >{{ $t("reset") }}</pages-btn
                  >
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </v-slide-y-transition>
    </v-container>
    <v-row justify="center">
      <v-dialog v-if="dialog" v-model="dialog" persistent max-width="536">
        <v-card class="dialog_card" v-if="success">
          <v-card-actions class="pr-4">
            <v-row justify="end">
              <v-btn icon @click="redirectButton()">
                <v-icon color="black" size="24">mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
          <div class="text-xs-center">
            <v-row v-if="!gs.isMobile()" justify="center">
              <v-icon color="primary" size="100">mdi-check-circle</v-icon>
            </v-row>
            <v-card-title class="dialog_card_title">
              {{ $t("change_pwd_success") }}
            </v-card-title>
            <v-card-text class="dialog_card_text">
              {{ $t("change_pwd_success_2") }}</v-card-text
            >
          </div>
          <v-card-actions class="justify-center pb-8">
            <v-btn
              outlined
              color="primary"
              class="redirect_btn"
              style="padding: 3% 15% !important"
              :style="
                gs.isMobile()
                  ? 'border-radius: 8px; font-size: 16px'
                  : 'border-radius: 15px; font-size: 24px'
              "
              @click="redirectButton()"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="dialog_card" v-else>
          <v-card-actions class="pr-4">
            <v-row justify="end">
              <v-btn icon @click="dialog = false">
                <v-icon color="black" size="24">mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
          <div class="text-xs-center">
            <v-card-title class="dialog_card_title">
              {{ $t("change_pwd_error") }}
            </v-card-title>
            <v-card-text class="dialog_card_text">{{
              $t("change_pwd_error_2")
            }}</v-card-text>
          </div>
          <v-card-actions class="justify-center pb-8">
            <v-btn
              outlined
              color="primary"
              class="redirect_btn"
              style="padding: 3% 15% !important"
              :style="
                gs.isMobile()
                  ? 'border-radius: 8px; font-size: 16px'
                  : 'border-radius: 15px; font-size: 24px'
              "
              @click="redirectButton()"
            >
              {{ $t("pwd_reset") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import PagesBtn from "@/views/pages/components/Btn.vue";
import {
  verifyRequirements,
  checkPasswordMinCharacter,
} from "@/shared/helpers/validatePassword";
import ValidateStrengthPassword from "@/components/dxa/Password/ValidateStrengthPassword";

export default {
  name: "NewPassword",
  components: {
    "pages-btn": PagesBtn,
    ValidateStrengthPassword,
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    langs: [
      {
        text: "English",
        value: "en",
      },
      {
        text: "Português",
        value: "pt",
      },
    ],
    selectedLanguage: null,
    loading: false,
    valid: true,
    lazy: false,
    error: null,
    show1: false,
    show2: false,
    password: "",
    rePassword: "",
    step: 3,
    clicked: false,
    dialog: false,
    success: false,
    user_id: null,
    verifyRequirements,
    checkPasswordMinCharacter,
  }),
  watch: {
    step() {
      this.$store.commit("SET_STEP", this.step);
    },
  },
  computed: {
    color() {
      if (
        this.configs &&
        this.$route.query.partner_name != "DXAInvestPrivate"
      ) {
        return this.configs.color;
      } else {
        const documentStyle = window.getComputedStyle(document.documentElement);
        const documentPrimaryColor =
          documentStyle.getPropertyValue("--primary");

        return documentPrimaryColor;
      }
    },
    passwordsMatch() {
      return () =>
        this.password === this.rePassword || this.$t("pswds_dnt_match");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    min_valid() {
      return (v) => v.length >= 8 || this.$t("password_rule_5");
    },
    special_valid() {
      return (v) => /[*&$!@#?]/.test(v) || this.$t("password_rule_4");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
    uppercase_required() {
      return (v) => /[A-Z]/.test(v) || this.$t("uppercase_required");
    },
    number_required() {
      return (v) => /[0-9]/.test(v) || this.$t("number_required");
    },
    lowercase_required() {
      return (v) => /[a-z]/.test(v) || this.$t("lowercase_required");
    },
    passwordMinCharacter() {
      return (v) =>
        checkPasswordMinCharacter(v) || this.$t("password_min_character");
    },
  },
  methods: {
    redirectButton: function () {
      this.dialog = false;
      if (this.success) {
        this.$router.push({ name: "LoginClient" });
      }
    },
    checkBackFunction: function () {
      if (this.step == 1) {
        this.$router.push({ name: "LoginClient" });
      } else {
        this.$router.push({ name: "ForgotPasswordClient" });
      }
    },
    async validate_link() {
      this.loading = true;
      this.error = null;

      let token = this.$route.query.token;
      let data = { UserId: this.user_id, NewCode: token };

      await this.apiService
        .postRequest("user/forgot-password-link/validate", data)
        .then((result) => {
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.error(this.$t("invalid_link"));
          // this.$router.push({ name: "LoginClient" });
        });
    },
    checkForm: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;

      let result = this.$refs.form.validate();
      let passwordIsValid = this.verifyRequirements(this.password);

      // tem que ser === true, porque o helper que valida a senha, retorna a string com a mensagem de erro e true quando a senha for válida.
      if (result && passwordIsValid === true) {
        this.save_new_password();
      } else {
        this.loading = false;
        this.success = false;
      }
    },
    async save_new_password() {
      this.error = null;

      let token = this.$route.query.token;
      let data = {
        NewPassword: this.password,
        UserId: this.user_id,
        NewCode: token,
      };

      await this.apiService
        .postRequest("user/forgot-password-link/reset", data)
        .then((result) => {
          this.loading = false;
          this.dialog = true;
          this.success = true;
        })
        .catch((err) => {
          this.error = err.body.message;
          this.loading = false;
          this.success = false;

          // se o erro for diferente de 400, mantemos a lógica do tratamento de erro do componente e mostramos o dialog.
          // se for 400, vamos mostrar o alerta com a mensagem de erro que o back traz.
          if (err.status !== 400) {
            this.dialog = true;
          }
        });
    },
    goFoward() {
      this.step += 1;
    },
    goBack() {
      this.step -= 1;
    },
    selectedLanguageAvatar: function () {
      if (this.selectedLanguage.value.toUpperCase() == "PT") {
        return require("../../../../../assets/Brasil-Icon.png");
      } else {
        return require("../../../../../assets/UK-Icon.png");
      }
    },
    changeLanguage: function (l) {
      this.$i18n.locale = l.value;
      this.$store.commit("SET_LANGUAGE", l.value);
      this.selectedLanguage = l;
      this.$router.go();
    },
  },
  beforeMount() {
    this.validate_link();
  },
  mounted() {
    this.langs.forEach((element) => {
      if (this.$i18n.locale == element.value) {
        this.selectedLanguage = element;
      }
    });
  },
};
</script>

<style>
.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi {
  background-color: var(--primary) !important;
}

.app_bar {
  display: none;
}
</style>

<style scoped>
.btn_lang {
  text-align: center;
  justify-content: Center;
  align-items: center;
  background-color: var(--white);
}
p {
  margin: 3px;
}
.p_en {
  color: var(--secondary);
}
.p_br {
  color: var(--primary);
  font-size: 12px;
}

.desktop_card {
  background-color: var(--dark) !important;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 12px !important;
  width: 64vw;
  height: 100% !important;
  margin-top: 1vh;
}

#new-password {
  background-color: var(--white) !important;
  min-height: 100vh;
  min-width: 100vw;
}

.confirm_title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: var(--dark);
}

.confirm_content {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.div_email,
.div_password {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.email_title,
.password_title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9c9c9c;
}

.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> .v-text-field__slot input::placeholder,
.my-input-style >>> .v-input__icon button {
  color: #9c9c9c;
}

.send_btn {
  position: relative;
  border-radius: 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px !important;
  min-width: 80% !important;
  margin-right: auto;
  height: 50px !important;
}

.steps {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: var(--white);
}

.card_content {
  width: 65%;
  height: 100%;
  background-color: var(--white);
  padding: 4% 2%;
  border-bottom-right-radius: 0 !important;
}

.v-toolbar__content {
  padding-left: 20px;
}

.x_logo {
  position: absolute;
  right: 30px;
  top: 40px;
  width: 6%;
}

.stars {
  position: absolute;
  transform: scaleX(-1);
  right: 30px;
  bottom: 42px;
  width: 28%;
}

.v-progress-linear__background {
  background-color: transparent !important;
}

.redirect_btn {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  border: 1px solid;
  padding: 3% 15% !important;
}

.dialog_card {
  background: var(--white) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 12px !important;
  height: max-content;
}

.dialog_card_title {
  color: var(--primary) !important;
  font-family: "Source Sans Pro" !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 48px;
  text-align: center !important;
  letter-spacing: 0.15px !important;
}

.dialog_card_text {
  color: var(--dark) !important;
  font-family: "Source Sans Pro" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 25px;
  text-align: center !important;
  letter-spacing: 0.15px !important;
  padding: 4vh 3vw 1vh !important;
}

@media (min-width: 1264px) {
  .dialog_card_title {
    font-size: 39px !important;
  }

  .dialog_card_text {
    font-size: 32px !important;
    line-height: 43px;
  }
}
</style>
